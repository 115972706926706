import { Paragraph } from '../../components/paragraph/Paragraph';
import banner from '../../assets/banner_plain.webp';
import icon1 from '../../assets/LaidotuviuPlanavimasOrganizavimas.svg';
import icon2 from '../../assets/MirusiojoPeгemimas.svg';
import icon3 from '../../assets/DokumentuTvarkymas.svg';
import icon4 from '../../assets/PalaikuSaugojimas.svg';
import icon5 from '../../assets/VelionioParuosimas.svg';
import icon6 from '../../assets/PalaikuKremavimas.svg';
import icon7 from '../../assets/LaidojimoReikmenys.svg';
import icon8 from '../../assets/TransportoPaslaugos.svg';
import icon9 from '../../assets/DuobkasiuPaslaugos.svg';
import icon10 from '../../assets/GiesmininkaiMuzikantai.svg';
import icon11 from '../../assets/FloristoPaslaugos.svg';
import icon12 from '../../assets/GedulingiPietus.svg';
import icon13 from '../../assets/KitosPaslaugos.svg';

import back from '../../assets/background.webp';

export const About = () => {
  return (
    <div style={{ background: `url(${back})` }}>
      <div className="relative sm:text-xl md:text-2xl lg:text-4xl">
        <img src={banner} alt="Laidojimo Paslaugos banner" />
        <div
          className="absolute top-1/2 left-10 transform -translate-y-1/2 text-center w-2/3"
          style={{ color: 'rgb(255,189,89)' }}
        >
          <p className="sm:pb-10">Laidojimo paslaugos Šiauliuose</p>
          <p className="py-1 sm:py-5">
            Tel:{' '}
            <a className="text-white" href="tel:+37068503084">
              +37068503084
            </a>{' '}
            visą parą.
          </p>
          <p>
            El. paštas:{' '}
            <a
              className="text-white"
              href="mailto:ricardolaidojimopaslaugos@gmail.com"
            >
              ricardolaidojimopaslaugos@gmail.com
            </a>
          </p>
          <p className="italic pt-3 sm:pt-10">
            Ištikus nelaimei mes pasiruošę jums padėti bet kuriuo paros metu.
          </p>
        </div>
      </div>
      <h1
        className="text-center capitalize text-4xl font-normal py-3"
        style={{ color: 'rgb(164,112,42)' }}
      >
        Paslaugos
      </h1>
      <p className="text-center font-light font-serif px-4">
        Ričardo laidojimo paslaugos jau daugiau nei 16 metų rūpinasi
        atsisveikinimu su velioniais, kad jų artimiesiems būtų kaip įmanoma
        labiau palengvinti naštą ir be papildomų rūpesčių atsisveikinti su Jums
        brangiu žmogumi.
      </p>
      <div className="flex flex-1 flex-wrap justify-center py-4">
        <Paragraph icon={icon1} text="Laidotuvių planavimas ir organizavimas" />
        <Paragraph icon={icon2} text="Mirusiojo paėmimas iš namų, ligoninės" />
        <Paragraph icon={icon3} text="Dokumentų tvarkymas" />
        <Paragraph icon={icon4} text="Palaikų saugojimas" />
        <Paragraph
          icon={icon5}
          text="Velionio sanitarinis paruošimas ir aprengimas"
        />
        <Paragraph icon={icon6} text="Palaikų kremavimas" />
        <Paragraph icon={icon7} text="Laidojimo reikmenys" />
        <Paragraph icon={icon8} text="Transporto paslaugos" />
        <Paragraph icon={icon9} text="Duobkasių paslaugos" />
        <Paragraph icon={icon10} text="Giesmininkai / muzikantai" />

        <Paragraph icon={icon11} text="Floristo paslaugos" />
        <Paragraph icon={icon12} text="Gedulingi pietūs" />
        <Paragraph icon={icon13} text="Kitos paslaugos" />
      </div>
    </div>
  );
};
