import logo from '../../assets/logo.webp';
export const Header = () => {
  return (
    <div
      className="flex px-4 sticky top-0 z-10"
      style={{ backgroundColor: 'rgb(45, 41, 38)' }}
    >
      <img
        className="object-contain"
        src={logo}
        alt="logo"
        width={200}
        height={100}
      />
    </div>
  );
};
