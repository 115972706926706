interface IParagraph {
  icon: string;
  text: string;
}

export const Paragraph: React.FC<IParagraph> = ({ icon, text }) => {
  return (
    <div
      className="border pb-4 mb-3 mx-4"
      style={{ backgroundColor: 'rgb(240, 240, 230)' }}
    >
      <img src={icon} alt={text} />
      <p className="text-center text-xl" style={{ color: 'rgb(164,112,42)' }}>
        {text}
      </p>
    </div>
  );
};
