import { Switch, Route } from 'react-router-dom';
import { About } from './pages/about/About';

export const PageRouter = () => {
  return (
    <Switch>
      <Route path="/">
        <About />
      </Route>
    </Switch>
  );
};
