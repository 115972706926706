import emailIcon from '../../assets/email.svg';
import phoneIcon from '../../assets/phone.svg';

const date = new Date();

export const Footer = () => {
  return (
    <div
      className="flex flex-col p-4 text-white"
      style={{ backgroundColor: 'rgb(45, 41, 38)' }}
    >
      <p className="text-center">Kontaktai</p>
      <div className="flex flex-1">
        <p className="flex flex-1 font-light font-serif">
          Laidojimo paslaugos Šiauliuose. Visais laidotuvių klausimais
          konsultuojame visą parą, atvykstame į jums patogią vietą Šiauliuose ir
          Šiaulių rajone.
        </p>
        <div className="flex flex-1 flex-wrap justify-around">
          <span className="inline-flex p-1 flex-wrap items-center">
            <img src={phoneIcon} alt="telefonas" width={60} height={60} />
            <a href="tel:+37068503084">+37068503084</a>
          </span>

          <span className="inline-flex p-1 flex-wrap items-center">
            <img src={emailIcon} alt="email" width={60} height={60} />
            <a href="mailto:ricardolaidojimopaslaugos@gmail.com">
              Susisiekti el. paštu
            </a>
          </span>
        </div>
      </div>
      <p className="pt-5">© Visos teisės saugomos {date.getFullYear()}</p>
    </div>
  );
};
