import { BrowserRouter as Router } from 'react-router-dom';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { PageRouter } from './PageRouter';

export const App: React.FC = () => {
  return (
    <div className="h-full flex flex-col">
      <Router>
        <Header />
        <div className="flex flex-col flex-1">
          <PageRouter />
        </div>
        <Footer />
      </Router>
    </div>
  );
};
